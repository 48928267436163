<script lang="ts" setup>
import type { FormResult } from '~ui/types/formResult';

const props = defineProps<FormResult>();

defineEmits<{
  clear: [];
}>();

const variantClasses = computed<string>(() =>
  twMerge(
    cva('flex items-center justify-between p-3 bg-white rounded-xl border space-x-3', {
      variants: {
        variant: {
          success: 'border-green-600 text-green-600',
          info: 'border-blue-600 text-blue-600',
          error: 'border-red-600 text-red-600',
        },
      },
    })({
      variant: props.variant,
    }),
  ),
);
</script>

<template>
  <div role="alert" aria-live="assertive" :data-testid="`ui-alert-message-${variant}`" :class="variantClasses">
    <div class="flex items-center space-x-3">
      <UIAssetIcon path="info" size="md" />
      <div class="flex">
        <span class="sr-only">{{ $t('general.alertNotification.srOnlyPrefix') }}</span>
        <UIHtml :html="message" />
      </div>
      <slot name="actionButtons" />
    </div>
    <button type="button" class="flex items-center w-4 h-4 text-primary-500" aria-label="close alert" @click="$emit('clear')">
      <UIAssetIcon path="cross" size="xs" />
    </button>
  </div>
</template>
